<template>
  <div>
    <!-- 联系我们首图 -->
    <div class="contactBanner">
      <img src="@/assets/img/ContactUs/banner_contactus.png" />
    </div>
    <!-- 地图 -->
    <div class="contactMap">
      <div>
        <div class="contactMap_info">
          <div>
            <div><img src="@/assets/img/ContactUs/pic-bontact-tel.png" /></div>
            <div>020-3203 9269</div>
          </div>
          <div>
            <div>
              <img src="@/assets/img/ContactUs/pic-bontact-phone.png" />
            </div>
            <div>19924720960</div>
          </div>
          <div>
            <div>
              <img src="@/assets/img/ContactUs/pic-bontact-wechat.png" />
            </div>
            <div>VLimYK</div>
          </div>
        </div>
        <div>
          <baidu-map class="map" :center="markerPoint" :zoom="15.1">
            <bm-marker
              :position="markerPoint"
              :dragging="true"
              @click="infoWindowOpen"
            >
              <bm-info-window
                :show="show"
                @close="infoWindowClose"
                @open="infoWindowOpen"
              >
                <div class="mapTips">
                  <div>广东微林软件有限公司</div>
                  <br />
                  <div>
                    广州市开发区科学城科研路12号生产力促进中心 205、215、216号
                  </div>
                </div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>
    <!-- 给我们留言 -->
    <!-- <div class="contactLeave">
      <div class="contactLeave_message">
        <div class="contactLeave_messageTitle">
          <div>给我们留言</div>
          <div></div>
        </div>
        <div class="contactLeave_messageInput">
          <div class="contactLeave_box">
            <div>
              <input type="text" placeholder="姓名" />
            </div>
            <div>
              <input type="text" placeholder="电话" />
            </div>
            <div>
              <input type="text" placeholder="邮箱" />
            </div>
          </div>
          <div class="contactLeave_address">
            <input type="text" placeholder="地址" />
          </div>
          <div class="contactLeave_details">
            <textarea placeholder="输入留言内容"></textarea>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      markerPoint: { lng: 113.448664, lat: 23.175464 },
      show: true,
    };
  },
  methods: {
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
/* 联系我们-首图 */
.contactBanner img {
  width: 100%;
  height: 136px;
  display: flex;
  object-fit: cover;
}
/* 地图 */
.contactMap {
  width: 100%;
  margin: 40px 0;
  box-sizing: border-box;
}
.contactMap_info {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
}
.contactMap_info img {
  width: 24px;
  height: 24px;
  display: flex;
  object-fit: cover;
  margin-right: 4px;
}
.contactMap_info > div {
  font-size: 14px;
  color: #47b0b4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.map {
  width: 100%;
  height: 400px;
  padding: 0 12px;
  box-sizing: border-box;
}
.mapTips {
  width: 200px;
  height: 100px;
}
.mapTips > div:nth-of-type(1) {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.mapTips > div:nth-of-type(2) {
  color: #999999;
  font-size: 10px;
  line-height: 15px;
}
/* 给我们留言 */
.contactLeave {
  position: relative;
  background-image: url('../assets/img/ContactUs/bg-ly.png');
  background-repeat: no-repeat;
  background-position: center;
}
.contactLeave_message {
  width: 100%;
  padding: 40px 0;
}
.contactLeave_messageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contactLeave_messageTitle > div:nth-of-type(1) {
  font-size: 24px;
  color: #ffffff;
}
.contactLeave_messageTitle > div:nth-of-type(2) {
  margin-top: 8px;
  width: 30px;
  height: 2px;
  background: #ffffff;
}
/* 给我留言-输入框 */
.contactLeave_messageInput {
  width: 100%;
  margin-top: 40px;
  padding: 0 48px;
  box-sizing: border-box;
}
.contactLeave_messageInput input {
  outline: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff1c;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #aaaaaa;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #aaaaaa;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #aaaaaa;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #fff;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #fff;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #fff;
}
.contactLeave_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contactLeave_box > div {
  width: 100%;
  margin-bottom: 30px;
}
.contactLeave_box input {
  color: #fff;
  width: 100%;
  height: 40px;
  font-size: 18px;
}
.contactLeave_address {
  width: 100%;
  margin-bottom: 30px;
}
.contactLeave_address input {
  color: #fff;
  width: 100%;
  height: 40px;
  font-size: 18px;
}
.contactLeave_details {
  width: 100%;
}
.contactLeave_details textarea {
  width: 100%;
  height: 100px;
  font-size: 18px;
  border: 1px solid #fff;
  background-color: #ffffff1c;
  padding: 10px;
  color: #fff;
  outline: none;
  box-sizing: border-box;
}
</style>